<template>
  <div class="main-frame">
    <h3 class="title">회원 관리</h3>
    <div>
      <mdb-datatable-2
          v-model="data"
          class="text-center"
          hover
          borderless
          defaultRow="-"
          fullPagination
          @selected='onSelected($event)'
          noFoundMessage="검색된 데이터가 없습니다."/>
      <div class="data-null" v-show="isDataNull">
        <p>등록된 유저가 없습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mdbDatatable2,} from "mdbvue";
import admin_userList_columns from "@/assets/data/columns/admin_userList_columns";
import {getDate, getDateTime} from "@/lib/prettyDate";
import {firestore} from "@/firebase/firebaseConfig";
export default {
    name: "MasterMain",
  components: {mdbDatatable2},
  data() {
    return {
      fbCollection: 'user',
      data: {
        columns: admin_userList_columns,
        rows: []
      },
      isDataNull: false,
    }
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData()
    },

    getTableData() {
      const self = this;
      self.data.rows.splice(0);
      firestore.collection(self.fbCollection)
          .orderBy('regDate', "asc")
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size === 0) {
              return
            }
            self.isDataNull = false
            let _number = 0
            querySnapshot.forEach((doc) => {
              const _data = doc.data()
              _data['docId'] = doc.id
              _data['email'] = _data.auth.email
              _data['orderNumber'] = ++_number
              const date = new Date(doc.data().regDate.seconds * 1000);
              if (_data.currentLog) {
                const date2 = new Date(doc.data().currentLog.seconds * 1000);
                _data.currentLog = getDateTime(date2)
              }
              _data.regDate = getDate(date)
              self.data.rows.push(_data);
            });
          })
    },
    onSelected(value) {
      // const self = this;
      console.log(value)
        // self.$router.push({name: 'MasterUserEdit', params: {uid: value.docId}})
    },
  }
}
</script>

<style scoped>
/*.main-frame {*/
/*  padding: 15px;*/
/*}*/

.main-frame {
  width: 1280px;
  background: white;
  padding: 30px 16px;
  margin: 0 auto;
  font-weight: 400;
  min-height: calc(100vh - 70px);
}

.title {
  font-weight: 600;
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 15px;
}

.sub-title {
  font-weight: 500;
  margin-bottom: 15px;
}

.text-center {
  font-weight: 400;
  font-size: 24px;
  border: 0;
  border-radius: 10px;
  background: #ffffff !important;
}

::v-deep(.leader) {
  border-radius: 100px;
  border: 3px solid #4C9AF5;
  padding: 0;
  color: #4C9AF5;
  font-weight: 500;
}


::v-deep(.mdb-datatable .table-header tr) {
  background: #f4f4f5;
  border-top: 2px #E4E4E7 solid;
  padding: 0 16px;
}

::v-deep(.mdb-datatable td) {
  cursor: pointer !important;
  padding: 14px 0 !important;
  font-size: 16px;
}

::v-deep(.mdb-datatable tr) {
  border-top: 1px #E4E4E7 solid;
  padding: 0 16px;
}

::v-deep(.mdb-datatable tr:hover) {
  background: #fafafa;
}

::v-deep(.mdb-datatable .table-header th),
::v-deep(.mdb-datatable td) {
  font-weight: 500;
  color: #71717A;
  padding: 14px 0 14px;
  font-size: 16px;
}

::v-deep(.mdb-datatable tr:nth-last-child(1)) {
  border-bottom: 2px #E4E4E7 solid;
}

::v-deep(.mdb-datatable th:first-child),
::v-deep(.mdb-datatable td:first-child) {
  width: 70px;
  padding-left: 16px;
  padding-right: 10px;
}

</style>