export default [
    {
        label: '태그',
        field: 'tag',
        sort: true
    },
    {
        label: '직업',
        field: 'job',
        sort: true
    },
    {
        label: '이메일',
        field: 'email',
        sort: false
    },
];
